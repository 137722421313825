<template>
  <v-container fluid style="text-align:center;">
    <v-card flat>
      <h2>Gesamt</h2>
      <table style="font-size:100%;">
        <colgroup>
          <col style="width:41%;" />
          <col style="width:8%;" />
          <col style="width:2%;" />
          <col style="width:8%;" />
          <col style="width:41%;" />
        </colgroup>
        <tr style="font-size:150%;font-weight:bold;">
          <td style="text-align:right;">{{ h.team.name }}</td>
          <td>{{ mannschaftgesamtergebnis(e, r, h.team._id) | float2_0 }}</td>
          <td></td>
          <td>{{ mannschaftgesamtergebnis(e, r, g.team._id) | float2_0 }}</td>
          <td style="text-align:left;">{{ g.team.name }}</td>
        </tr>
      </table>
    </v-card>
    <v-card v-for="d in d" flat :key="d._discipline">
      <h2>{{ d.name }}</h2>
      <table style="font-size:100%;">
        <colgroup>
          <col style="width:17%;" />
          <col style="width:8%;" />
          <col style="width:8%;" />
          <col style="width:8%;" />
          <col style="width:8%;" />
          <col style="width:2%;" />
          <col style="width:8%;" />
          <col style="width:8%;" />
          <col style="width:8%;" />
          <col style="width:8%;" />
          <col style="width:17%;" />
        </colgroup>
        <tr style="font-size:150%;font-weight:bold;">
          <td colspan="4" style="text-align:right;">{{ h.team.name }}</td>
          <td>{{ mannschaftgeraetergebnis(e, r, h.team._id, d._discipline) | float2_0 }}</td>
          <td style="background:white;"></td>
          <td>{{ mannschaftgeraetergebnis(e, r, g.team._id, d._discipline) | float2_0 }}</td>
          <td colspan="4" style="text-align:left;">{{ g.team.name }}</td>
        </tr>
        <tr class="head">
          <td>Turner</td>
          <td>D-Note</td>
          <td>Teile</td>
          <td>Abzug</td>
          <td>Endnote</td>
          <td style="background:white;"></td>
          <td>Endnote</td>
          <td>Abzug</td>
          <td>Teile</td>
          <td>D-Note</td>
          <td>Turner</td>
        </tr>
        <tr v-for="(l, i) in table(d._discipline)" :key="`${d._discipline}-${i}`" :class="{even: i % 2 === 0}">
          <template v-if="l.h">
            <td style="font-size:120%;font-weight:bold;">{{ l.h.person | person }}</td>
            <td><v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >
                {{ l.h.result.dscore | float1_0 }}
                </span>
              </template>
              <span>
                <template v-if="l.h.result.i">I-Teile: {{ l.h.result.i }}<br></template>
                <template v-if="l.h.result.h">H-Teile: {{ l.h.result.h }}<br></template>
                <template v-if="l.h.result.g">G-Teile: {{ l.h.result.g }}<br></template>
                <template v-if="l.h.result.f">F-Teile: {{ l.h.result.f }}<br></template>
                <template v-if="l.h.result.e">E-Teile: {{ l.h.result.e }}<br></template>
                <template v-if="l.h.result.d">D-Teile: {{ l.h.result.d }}<br></template>
                <template v-if="l.h.result.c">C-Teile: {{ l.h.result.c }}<br></template>
                <template v-if="l.h.result.b">B-Teile: {{ l.h.result.b }}<br></template>
                <template v-if="l.h.result.a">A-Teile: {{ l.h.result.a }}<br></template>
                <template v-if="l.h.result.ne">NE-Teile: {{ l.h.result.ne }}<br></template>
                <template v-if="l.h.result.eg">EG: {{ l.h.result.eg }}<br></template>
              </span>
            </v-tooltip></td>
            <td>{{ l.h.result.parts }}</td>
            <td>{{ l.h.result.deduction | float2_0 }}</td>
            <td style="font-size:120%;">{{ l.h.result.final | float2_0 }}</td>
          </template>
          <template v-else>
            <td colspan="5"></td>
          </template>
          <td style="background:white;"></td>
          <template v-if="l.g">
            <td style="font-size:120%;">{{ l.g.result.final | float2_0 }}</td>
            <td>{{ l.g.result.deduction | float2_0 }}</td>
            <td>{{ l.g.result.parts }}</td>
            <td><v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >
                {{ l.g.result.dscore | float1_0 }}
                </span>
              </template>
              <span>
                <template v-if="l.g.result.i">I-Teile: {{ l.g.result.i }}<br></template>
                <template v-if="l.g.result.h">H-Teile: {{ l.g.result.h }}<br></template>
                <template v-if="l.g.result.g">G-Teile: {{ l.g.result.g }}<br></template>
                <template v-if="l.g.result.f">F-Teile: {{ l.g.result.f }}<br></template>
                <template v-if="l.g.result.e">E-Teile: {{ l.g.result.e }}<br></template>
                <template v-if="l.g.result.d">D-Teile: {{ l.g.result.d }}<br></template>
                <template v-if="l.g.result.c">C-Teile: {{ l.g.result.c }}<br></template>
                <template v-if="l.g.result.b">B-Teile: {{ l.g.result.b }}<br></template>
                <template v-if="l.g.result.a">A-Teile: {{ l.g.result.a }}<br></template>
                <template v-if="l.g.result.ne">NE-Teile: {{ l.g.result.ne }}<br></template>
                <template v-if="l.g.result.eg">EG: {{ l.g.result.eg }}<br></template>
              </span>
            </v-tooltip></td>
            <td style="font-size:120%;font-weight:bold;">{{ l.g.person | person }}</td>
          </template>
          <template v-else>
            <td colspan="5"></td>
          </template>
        </tr>
      </table>
    </v-card>
    <v-card v-if="topathletes.length > 0" flat>
      <h2>Beste Sechskämpfer</h2>
      <table style="font-size:100%;">
        <tr class="head">
          <td>Turner</td>
          <td>Mannschaft</td>
          <td>Punkte</td>
        </tr>
        <tr
          v-for="(a, i) in topathletes"
          :key="a.person._id"
          :class="{even: i % 2 === 0}"
        >
          <td>{{ a.person | person }}</td>
          <td>{{ a.team.name }}</td>
          <td>{{ a.final | float2_0 }}</td>
        </tr>
      </table>
    </v-card>
    <v-btn block @click="ergebnis(e, df, r)" color="primary">PDF-Download</v-btn>
  </v-container>
</template>

<script>
import { useCalc } from '@/views/components/stb_m_2021/plugins/calc'
import { usePdf } from '@/views/components/stb_m_2021/plugins/pdf'

export default {
  name: 'wk_4aus5',

  setup (props, context) {
    return {
      ...useCalc(props, context),
      ...usePdf(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  computed: {
    d () {
      return this.geraete(this.e, this.df)
    },
    h () {
      return this.heim(this.e)
    },
    g () {
      return this.gast(this.e)
    },
    topathletes () {
      return this.e?.topathletes?.filter(a => a.disciplines === 6)?.sort((a, b) => a.final < b.final ? 1 : -1) || []
    }
  },

  methods: {
    he (did) {
      const heim = this.heim(this.e)
      const turner = this.mannschaftturnerangeraet(this.e, this.r, heim?.team?._id, did)

      return {
        team: heim.team,
        athletes: turner
      }
    },
    ga (did) {
      const gast = this.gast(this.e)
      const turner = this.mannschaftturnerangeraet(this.e, this.r, gast?.team?._id, did)

      return {
        team: gast.team,
        athletes: turner
      }
    },
    max (did) {
      return Math.max(this.he(did).athletes.length, this.ga(did).athletes.length)
    },
    table (did) {
      const rows = []

      for (let i = 0; i < this.max(did); i++) {
        rows.push({
          h: this.he(did).athletes[i] || { result: {} },
          g: this.ga(did).athletes[i] || { result: {} }
        })
      }

      return rows
    }
  }
}
</script>

<style scoped>

</style>
